import { CompanyContent } from '@/api/data-contracts';
import { Text } from '@/components/typography/Text';
import { Flex, Table, Tooltip } from '@radix-ui/themes';
import React from 'react';
import { styled } from '@/stitches.config';
import Image from 'next/image';
import { Badge } from '@/components/layout/Badge';

const standardBadgeColors = {
  ISO42001: "pineglade",
  NIST_AI: "pineglade",
  SOC2: "pineglade",
  CCPA: "lupine",
  ISO27001: "silvermist",
  OWASP: "bloodmoon",
  GDPR: "lupine",
  HIPAA: "lupine",
  FedRAMP: "sunbark",
  PCIDSS: "sunbark",
};

const SubProcessorTableContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingBlock: '$large',
  width: '100%',
  '@media (max-width: 768px)': {
    paddingBlock: '$medium'
  }
});

const SubProcessorTable = styled(Table.Root, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: '$large',
  width: '100%',
  overflow: 'auto',
  '@media (max-width: 768px)': {
    display: 'none'
  }
});

const MobileContainer = styled('div', {
  display: 'none',
  marginTop: '$large',
  '@media (max-width: 768px)': {
    display: 'block'
  }
});

const MobileCard = styled('div', {
  marginBottom: '$large',
  padding: '$medium',
  borderRadius: '$medium',
  border: '1px solid $silvermist-200',
  backgroundColor: 'white'
});

const CardSection = styled('div', {
  marginBottom: '$medium',
  '&:last-child': {
    marginBottom: 0
  }
});

const SectionHeader = styled(Text, {
  display: 'block',
  marginBottom: '$xsmall'
});

const BadgesContainer = styled(Flex, {
  flexWrap: 'wrap',
  gap: '$small',
  justifyContent: 'flex-start'
});

const ResponsiveImage = styled(Image, {
  maxWidth: '100%',
  height: 'auto'
});

export default function TrustCenterSubProcessor({ companyContent }: { companyContent: CompanyContent }) {
  return (
    <SubProcessorTableContainer>
      <Text as="h1" size="large" weight="medium" color="primary">
        Sub-Processors
      </Text>

      {/* Desktop Table View */}
      <SubProcessorTable variant="surface" size="1">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>{renderResourceHeader('Name')}</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>{renderResourceHeader('Purpose')}</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>{renderResourceHeader('Compliance')}</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>{renderResourceHeader('Location')}</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {companyContent.sub_processors.map((subProcessor, index) => (
            <Table.Row key={`desktop-${subProcessor.name}-${index}`} align="center">
              <Table.Cell>
                <Flex direction="column" gap="3" align="center" style={{ padding: '1rem' }}>
                  <Tooltip content={subProcessor.name}>
                    <ResponsiveImage
                      src={subProcessor.logo.url}
                      alt={subProcessor.logo.alt}
                      width={subProcessor.logo.width}
                      height={subProcessor.logo.height}
                      priority={true}
                    />
                  </Tooltip>
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <Flex direction="column" gap="3" style={{ padding: '1rem' }}>
                  <Text as="p" size="medium" color="secondary">
                    {subProcessor.purpose}
                  </Text>
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <BadgesContainer direction="row" style={{ padding: '1rem' }}>
                  {subProcessor.standards.slice(0, 5).map((standard, i) => (
                    <Badge
                      key={`standard-${standard}-${i}`}
                      variant="fill"
                      color={standardBadgeColors[standard.replace('-', '')] || "defaultColor"}
                    >
                      {standard}
                    </Badge>
                  ))}
                </BadgesContainer>
              </Table.Cell>
              <Table.Cell>
                <Flex direction="column" gap="3" style={{ padding: '1rem' }}>
                  <Text as="p" size="medium" color="secondary">
                    {subProcessor.location.join(', ')}
                  </Text>
                </Flex>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </SubProcessorTable>

      {/* Mobile Card View */}
      <MobileContainer>
        {companyContent.sub_processors.map((subProcessor, index) => (
          <MobileCard key={`mobile-${subProcessor.name}-${index}`}>
            <CardSection>
              <Text as="p" size="medium" weight="semibold">
                {subProcessor.purpose}
              </Text>
            </CardSection>

            <CardSection>
              <SectionHeader as="p" size="small" weight="medium" color="secondary">
                Compliance
              </SectionHeader>
              <BadgesContainer direction="row">
                {subProcessor.standards.slice(0, 5).map((standard, i) => (
                  <Badge
                    key={`mobile-standard-${standard}-${i}`}
                    variant="fill"
                    color={standardBadgeColors[standard.replace('-', '')] || "defaultColor"}
                  >
                    {standard}
                  </Badge>
                ))}
              </BadgesContainer>
            </CardSection>

            <CardSection>
              <SectionHeader as="p" size="small" weight="medium" color="secondary">
                Location
              </SectionHeader>
              <Text as="p" size="medium" color="secondary">
                {subProcessor.location.join(', ')}
              </Text>
            </CardSection>
          </MobileCard>
        ))}
      </MobileContainer>
    </SubProcessorTableContainer>
  );
}

function renderResourceHeader(title: string) {
  return (
    <Flex direction="column" gap="3" style={{ padding: '1rem' }}>
      <Text as="p" size="medium" color="secondary" weight="semibold">
        {title}
      </Text>
    </Flex>
  );
}
