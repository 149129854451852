import { Flex } from '@radix-ui/themes';
import { Text } from '../typography/Text';
import { Card, CardPadding } from '../layout/Card';
import Image from 'next/image';
import { CheckCircleIcon, ChevronRightIcon, XCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { styled } from '@/stitches.config';
import { useRouter } from 'next/router';
import { CompanyContent } from '@/api/data-contracts';
import { Row } from "../layout/Row";
import { WolfiaLogoIcon } from "../logo/WolfiaLogoIcon";

const MainGridContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '$xlarge',
  padding: '$large',
  width: '100%',
  '@media (max-width: 1024px)': {
    gap: '$large',
  },
  '@media (max-width: 768px)': {
    gridTemplateColumns: '1fr',
    gap: '$medium',
    padding: '$medium',
  },
  '@smallDevices': {
    padding: '$small',
  },
})

const TitlePadding = styled('div', {
  paddingTop: '$medium',
  paddingLeft: 'calc($large + $large)',
  '@media (max-width: 768px)': {
    paddingTop: '$small',
    paddingLeft: '$large',
  },
  '@smallDevices': {
    paddingLeft: '$medium',
  },
})

const ModelsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '$medium',
  width: '100%',
})

const ModelsLogosContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$large',
  padding: '$large',
  '@media (max-width: 768px)': {
    gap: '$medium',
    padding: '$medium',
  },
  '@smallDevices': {
    padding: '$small',
    gap: '$small',
  },
})

const ResponsiveImage = styled(Image, {
  maxWidth: '100%',
  height: 'auto',
  '@smallDevices': {
    maxWidth: '90%',
  },
})

const DataUseContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
})

const ControlsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
})

export const GreenCheckCircleIcon = styled(CheckCircleIcon, {
  color: '$pineglade-500',
  minWidth: '20px',
  minHeight: '20px',
})

export const GrayXCircleIcon = styled(XCircleIcon, {
  color: '$silvermist-300',
  minWidth: '20px',
  minHeight: '20px',
})

const ItemText = styled(Text, {
  wordBreak: 'break-word',
  flexGrow: 1,
})

const CardTitle = styled(Text, {
  wordBreak: 'break-word',
})

const CardDescription = styled(Text, {
  wordBreak: 'break-word',
})

function ControlCard({title, children, onClick}) {
  const limit = 4
  const childArray = React.Children.toArray(children)
  const isExceedingDisplayLimit = childArray.length > limit
  const childrenToShow = isExceedingDisplayLimit ? childArray.slice(0, limit) : childArray

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick()
      event.preventDefault()
    }
  }

  return (
    <HoverCard
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <CardPadding>
        <Flex direction="column" gap="3">
          <Flex justify="between" align="center">
            <CardTitle as="h3" size="medium" color="primary" weight="semibold">
              {title}
            </CardTitle>
            <ChevronRightIcon width="1rem" height="1rem"/>
          </Flex>
          {childrenToShow}
          {isExceedingDisplayLimit && (
            <Text as="p" size="medium" color="link">
              +{childArray.length - limit} more
            </Text>
          )}
        </Flex>
      </CardPadding>
    </HoverCard>
  )
}

function ControlCardItem({control, status}) {
  return (
    <Flex direction="row" gap="3" align="center" style={{alignItems: 'center'}}>
      <div style={{flexShrink: 0}}>
        {status ? <GreenCheckCircleIcon width="20" height="20"/> : <GrayXCircleIcon width="20" height="20"/>}
      </div>
      <ItemText as="p" size="medium" color="secondary">
        {control}
      </ItemText>
    </Flex>
  )
}

function UsageItem({use, check: check}) {
  return (
    <Flex direction="row" gap="3" align="center" style={{alignItems: 'center'}}>
      <div style={{flexShrink: 0}}>
        {check == true ? <GreenCheckCircleIcon width="20" height="20"/> : <GrayXCircleIcon width="20" height="20"/>}
      </div>
      <ItemText as="p" size="medium" color="secondary">
        {use}
      </ItemText>
    </Flex>
  )
}

const MainWrapper = styled(Flex, {
  width: '100%',
  justifyContent: 'center',
})

const ContentContainer = styled(Flex, {
  width: '100%',
  maxWidth: 1200,
  marginLeft: 'auto',
  marginRight: 'auto',
})

export default function TrustCenterOverview({companyContent}: { companyContent: CompanyContent }) {
  const router = useRouter()

  const navigateToControl = (category) => {
    router.push(`/?tab=controls#${category.toLowerCase().replace(/\s+/g, '-')}`, undefined, {shallow: true})
  }

  return (
    <MainWrapper direction="row" gap="4">
      <ContentContainer direction="column" gap="4">
        <ModelsContainer>
          <TitlePadding>
            <Text as="p" size="large" color="primary" weight="medium">
              Models
            </Text>
          </TitlePadding>
          <MainGridContainer>
            <Card>
              <CardPadding>
                <Flex direction="column" gap="3">
                  <Text as="h3" size="small" color="accent" weight="medium">
                    Architecture
                  </Text>
                  <CardTitle as="h2" size="xlarge" weight="medium">
                    {companyContent.ai_model_architecture.title}
                  </CardTitle>
                  <CardDescription as="p" size="medium" color="secondary">
                    {companyContent.ai_model_architecture.summary}
                  </CardDescription>
                  {companyContent.ai_model_architecture.items.map((item, index) => (
                    <UsageItem
                      key={index}
                      use={item.description}
                      check={item.is_supported}
                    />
                  ))}
                  <ModelsLogosContainer>
                    {companyContent.ai_model_architecture.logos.map((logo, index) => (
                      <ResponsiveImage
                        key={index}
                        src={logo.url}
                        alt={logo.alt}
                        width={logo.width}
                        height={logo.height}
                        priority={true}
                      />
                    ))}
                  </ModelsLogosContainer>
                </Flex>
              </CardPadding>
            </Card>
            <Card>
              <CardPadding>
                <Flex direction="column" gap="3">
                  <Text as="h3" size="small" color="accent" weight="medium">
                    Usage
                  </Text>
                  <CardTitle as="h2" size="xlarge" weight="medium">
                    {companyContent.ai_model_use.title}
                  </CardTitle>
                  <CardDescription as="p" size="medium" color="secondary">
                    {companyContent.ai_model_use.summary}
                  </CardDescription>
                  {companyContent.ai_model_use.items.map((item, index) => (
                    <UsageItem
                      key={index}
                      use={item.description}
                      check={item.is_supported}
                    />
                  ))}
                </Flex>
              </CardPadding>
            </Card>
          </MainGridContainer>
        </ModelsContainer>
        <DataUseContainer>
          <TitlePadding>
            <Text as="p" size="large" color="primary" weight="medium">
              Data use
            </Text>
          </TitlePadding>
          <MainGridContainer>
            <Card>
              <CardPadding>
                <Flex direction="column" gap="3">
                  <Text as="h3" size="medium" color="primary" weight="semibold">
                    Training
                  </Text>
                  <CardDescription as="p" size="medium" color="secondary">
                    {companyContent.data_use_training.summary}
                  </CardDescription>
                  {companyContent.data_use_training.items.map((item, index) => (
                    <UsageItem
                      key={index}
                      use={item.description}
                      check={item.is_supported}
                    />
                  ))}
                  {
                    companyContent.data_use_training.is_verified &&
                    <Row spacing="xsmall" alignVertical="center" style={{marginTop: '1rem'}}>
                      <WolfiaLogoIcon width="36" height="36"/>
                      <Text as="p" size="medium" weight="semibold">
                        Audited and verified by Wolfia
                      </Text>
                    </Row>
                  }
                </Flex>
              </CardPadding>
            </Card>
            <Card>
              <CardPadding>
                <Flex direction="column" gap="3">
                  <Text as="h3" size="medium" color="primary" weight="semibold">
                    Inference
                  </Text>
                  <CardDescription as="p" size="medium" color="secondary">
                    {companyContent.data_use_inference.summary}
                  </CardDescription>
                  {companyContent.data_use_inference.items.map((item, index) => (
                    <UsageItem
                      key={index}
                      use={item.description}
                      check={item.is_supported}
                    />
                  ))}
                </Flex>
              </CardPadding>
            </Card>
          </MainGridContainer>
        </DataUseContainer>
        <ControlsContainer>
          <TitlePadding>
            <Text as="p" size="large" color="primary" weight="medium">
              Controls
            </Text>
          </TitlePadding>
          <MainGridContainer>
            {companyContent.controls.map((controlCategory, categoryIndex) => (
              <ControlCard
                key={categoryIndex}
                title={controlCategory.category}
                onClick={() => navigateToControl(controlCategory.category)}
              >
                {controlCategory.items.map((item, itemIndex) => (
                  <ControlCardItem
                    key={itemIndex}
                    control={item.name}
                    status={item.status}
                  />
                ))}
              </ControlCard>
            ))}
          </MainGridContainer>
        </ControlsContainer>
      </ContentContainer>
    </MainWrapper>
  )
}

export const HoverCard = styled(Card, {
  cursor: 'pointer',
  transition: 'box-shadow 0.3s ease-in-out, transform 0.2s ease',
  '&:hover': {
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    transform: 'translateY(-2px)'
  },
  '&:focus': {
    outline: '2px solid $pineglade-300',
    outlineOffset: '2px'
  }
})
