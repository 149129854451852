import React, { ReactNode } from 'react';
import cn from 'classnames';

interface SectionProps {
  children?: ReactNode;
  backgroundColor?: string;
  fullWidth?: boolean;
  contentWidth?: 'content-full' | 'content-xl' | 'content-lg' | 'content-md' | 'content-sm' | 'content-xs' | 'content-xxs';
  padding?: 'p-lg' | 'p-md' | 'p-sm' | 'p-xs' | 'p-none';
  align?: 'center' | 'left';
  className?: string;
  sectionClassName?: string;
  id?: string;
}

const Section: React.FC<SectionProps> = ({
  children,
  backgroundColor = '',
  fullWidth = true,
  contentWidth = 'content-lg',
  padding = 'p-md',
  align = 'center',
  className = '',
  sectionClassName = '',
  id = '',
}) => {
  const containerClasses = cn(
    {
      'max-w-full': contentWidth === 'content-full',
      'max-w-[1600px]': contentWidth === 'content-xl',
      'max-w-[1400px]': contentWidth === 'content-lg',
      'max-w-[1200px]': contentWidth === 'content-md',
      'max-w-[1100px]': contentWidth === 'content-sm',
      'max-w-[1000px]': contentWidth === 'content-xs',
      'max-w-[640px]': contentWidth === 'content-xxs',
      'justify-start': align === 'left',
      'justify-center': align === 'center',
      'mx-auto flex flex-col xl:px-0 px-6': true,
    },
    className
  );

  const sectionClasses = cn(
    {
      'py-0': padding === 'p-none',
      'py-4': padding === 'p-xs',
      'py-8': padding === 'p-sm',
      'py-12': padding === 'p-md',
      'py-16': padding === 'p-lg',
      'w-full': true,
      'mx-6 rounded-lg': !fullWidth,
    },
    sectionClassName,
    backgroundColor
  );

  return (
    <section className={sectionClasses} id={id}>
      <div className={containerClasses}>
        {children}
      </div>
    </section>
  );
};

export default Section;
