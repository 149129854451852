import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { MarketingPage } from './MarketingPage';
import { Meta } from '../Meta';
import { MarketingBanner } from './MarketingBanner';
import CompanyLogosSection from '@/components/marketing/LogoGrid';
import { CTABox } from '@/components/marketing/CTABox';
import Section from '@/components/marketing/Section';
import { DEMO_URL } from '@/components/blog/DemoLink';
import { BoltIcon, CheckBadgeIcon, CheckCircleIcon, LightBulbIcon, ScaleIcon, ShieldCheckIcon, SparklesIcon, UserPlusIcon, } from '@heroicons/react/24/outline';


const KEY_LINE = 'Scale expertise, accelerate revenue';
const SUB_HEADLINE = "Wolfia's AI agent learns from your company knowledge. Sales and security teams answer buyer questions instantly, automate questionnaires, handle RFPs, and close deals faster.";
const CTA_GET_DEMO = "Schedule a demo";


const HeroContent = ({ title, url }) => (
  <div className='flex flex-col items-center w-full max-w-7xl mx-auto bg-white px-4 sm:px-6 lg:px-8 pt-8 pb-12 md:pb-16'>
    {title && url && <MarketingBanner title={title} link={url}/>}
    <h1 className="font-general-sans text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-semibold text-center mt-8 sm:mt-10 mb-5 tracking-tight">
      {KEY_LINE}
    </h1>
    <p className="font-general-sans text-lg sm:text-xl md:text-2xl text-center text-gray-600 max-w-4xl mb-8 sm:mb-10">
      {SUB_HEADLINE}
    </p>
    <div className="flex flex-col sm:flex-row gap-4 mb-10 sm:mb-12 w-full sm:w-auto justify-center">
      <Link href={DEMO_URL} className="inline-block px-8 py-3 bg-black text-white hover:bg-gray-800 font-semibold rounded-full duration-300 text-center text-lg">
        {CTA_GET_DEMO}
      </Link>
      <Link href="/#use-cases" className="inline-block px-8 py-3 bg-white text-black border border-gray-300 hover:bg-gray-100 font-semibold rounded-full duration-300 text-center text-lg">
        Learn how
      </Link>
    </div>
    <div className="w-full max-w-6xl p-1 border border-gray-200 rounded-xl bg-white">
      <div className="border border-gray-100 rounded-lg overflow-hidden">
        <Image src="/images/home/wolfia-hero-image.png" alt="Wolfia platform preview showing automated answers" width={1440} height={900} layout="responsive" objectFit="cover" priority />
      </div>
    </div>
  </div>
);

const HeroContainer = ({ title, url }) => (
  <div className="flex flex-col w-full bg-white">
    <div className="mx-auto px-4 sm:px-6 lg:px-8 w-full">
      <HeroContent title={title} url={url} />
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 w-full pb-16 md:pb-24 border-t border-gray-200 pt-16 md:pt-20">
      <p className="text-center text-gray-500 text-sm font-semibold uppercase tracking-wider mb-8">Trusted by teams at</p>
      <CompanyLogosSection />
    </div>
  </div>
);


const TimelineItem = ({ day, title, items, isActive, onHover }) => (
  <div
    onMouseEnter={() => onHover(day)}
    className={`p-6 lg:p-7 border rounded-xl bg-white transition-all duration-300 ease-in-out cursor-pointer relative group ${
      isActive
        ? 'opacity-100 shadow-xl scale-105 border-lupine-300 z-10'
        : 'opacity-80 border-gray-200 hover:opacity-100 hover:border-gray-300'
    }`}
  >
    <h3 className="font-semibold text-gray-900 mb-3">{title}</h3>
    <ul className="space-y-1.5">
      {items.map((item, index) => (
        <li key={index} className="flex items-start text-sm text-gray-700">
          <CheckBadgeIcon className="w-4 h-4 mr-2 mt-0.5 text-green-600 flex-shrink-0" />
          <span>{item.endsWith('.') ? item.slice(0, -1) : item}</span>
        </li>
      ))}
    </ul>
  </div>
);

const TimelineSection = () => {
  const [activeDay, setActiveDay] = useState(1);

  const days = [
    { day: 1, label: 'Day 1', title: 'Connect & Train', items: ['Securely link Drive, Slack, Notion, or upload files', 'AI learns your unique company knowledge instantly', 'Your data remains private and is never shared', 'Ask your first question and see the AI find info'], },
    { day: 2, label: 'Day 2', title: 'Automate & Ask', items: ['Upload your first questionnaire (eg, CAIQ)', 'Watch Wolfia draft precise answers in minutes', 'Answer a sample buyer security question instantly', 'See how the AI handles repetitive tasks'], },
    { day: 3, label: 'Day 3', title: 'Scale & Accelerate', items: ['Respond to real buyer questions in hours, not days', 'Complete a full security review 5x faster', 'Unblock deals previously waiting on experts', 'Quantify hours saved for your key team members'], },
  ];

  const progressPercentage = activeDay === 1 ? 0 : activeDay === 2 ? 50 : 100;

  return (
    <Section className="bg-white pt-16 lg:pt-24 pb-16 lg:pb-24 px-4 sm:px-6 lg:px-8 border-t border-gray-200">
      <div className="max-w-5xl mx-auto text-center mb-12 md:mb-16">
        <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900 mb-3 tracking-tight">See value from day one</h2>
        <p className="text-lg text-gray-600 max-w-4xl mx-auto">
          Wolfia delivers results immediately, transforming how your teams work within days, not months
        </p>
      </div>

      <div className="max-w-7xl mx-auto relative">
        <div className="max-w-4xl mx-auto mb-16 px-4 sm:px-8 relative pt-8">
          <div className="absolute left-[1%] transform bottom-full mb-2">
             <span className={`inline-block px-3 py-1 rounded-full text-xs font-medium transition-colors duration-300 ${ activeDay === 1 ? 'bg-lupine-100 text-lupine-800 font-semibold' : 'text-gray-500 bg-gray-100' }`}>
                Day 1
             </span>
          </div>
          <div className="absolute left-[50%] transform -translate-x-1/2 bottom-full mb-2">
             <span className={`inline-block px-3 py-1 rounded-full text-xs font-medium transition-colors duration-300 ${ activeDay === 2 ? 'bg-lupine-100 text-lupine-800 font-semibold' : 'text-gray-500 bg-gray-100' }`}>
                Day 2
             </span>
          </div>
          <div className="absolute right-[1%] transform bottom-full mb-2">
             <span className={`inline-block px-3 py-1 rounded-full text-xs font-medium transition-colors duration-300 ${ activeDay === 3 ? 'bg-lupine-100 text-lupine-800 font-semibold' : 'text-gray-500 bg-gray-100' }`}>
                Day 3
             </span>
          </div>

          <div className="relative h-1 bg-gray-200 rounded-full mt-2">
            <div className="absolute top-0 left-0 h-1 bg-lupine-500 rounded-full transition-all duration-500 ease-out" style={{ width: `${progressPercentage}%` }}></div>
            <div className={`absolute top-1/2 left-[0%] w-3 h-3 rounded-full -translate-y-1/2 transition-colors duration-300 ${activeDay >= 1 ? 'bg-lupine-500' : 'bg-gray-300'}`}></div>
            <div className={`absolute top-1/2 left-[50%] w-3 h-3 rounded-full -translate-x-1/2 -translate-y-1/2 transition-colors duration-300 ${activeDay >= 2 ? 'bg-lupine-500' : 'bg-gray-300'}`}></div>
            <div className={`absolute top-1/2 right-[0%] w-3 h-3 rounded-full -translate-y-1/2 transition-colors duration-300 ${activeDay >= 3 ? 'bg-lupine-500' : 'bg-gray-300'}`}></div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8 relative">
          {days.map(({ day, title, items }) => (
            <TimelineItem
              key={day}
              day={day}
              title={title}
              items={items}
              isActive={activeDay === day}
              onHover={setActiveDay}
            />
          ))}
        </div>
      </div>
    </Section>
  );
};


const UseCaseSectionLayout = ({ title, problem, solution, benefits, imageSrc, imageAlt, reverseLayout = false }) => (
  <div className={`max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 lg:gap-20 items-center py-12 lg:py-16`}>
    <div className={`order-2 ${reverseLayout ? 'md:order-1' : 'md:order-2'}`}>
      <h3 className="text-3xl md:text-4xl font-semibold text-gray-900 tracking-tight mb-8">{title.endsWith('.') ? title.slice(0, -1) : title}</h3>
      <p className="text-lg text-gray-600 mb-5">{problem}</p>
      <p className="text-lg text-gray-700 font-medium mb-6">{solution}</p>
      <ul className="space-y-3">
        {benefits.map((benefit, index) => (
          <li key={index} className="flex items-start">
            <CheckCircleIcon className="w-5 h-5 mr-2 mt-1 text-green-600 flex-shrink-0" />
            <span className="text-gray-700">{benefit}</span>
          </li>
        ))}
      </ul>
    </div>
    <div className={`order-1 ${reverseLayout ? 'md:order-2' : 'md:order-1'}`}>
      <div className="overflow-hidden drop-shadow-[0_15px_10px_rgba(0,0,0,0.1)] bg-transparent">
        <Image src={imageSrc} alt={imageAlt} width={1200} height={800} layout="responsive" objectFit="cover"/>
      </div>
    </div>
  </div>
);

const UseCaseExpertAnswersSection = () => (
  <UseCaseSectionLayout
    title="Get instant expert answers, keep deals moving"
    problem="Sales reps hit roadblocks needing technical or security details mid-conversation. Waiting kills momentum, delays deals, and frustrates buyers"
    solution="Provide Sales and Security teams with instant access to reliable, expert-level answers 24/7 via Slack or web app. Wolfia uses only your verified company knowledge, freeing up engineers and SMEs without interrupting their work"
    benefits={[
      "Accelerate deal cycles by answering buyer questions in hours, not days",
      "Maintain crucial deal momentum with rapid responses",
      "Free up valuable engineering and expert time for strategic work",
      "Build buyer confidence with consistent, accurate information",
    ]}
    imageSrc="/images/home/usecase-expert-answers.png"
    imageAlt="Illustration showing a sales rep getting an instant answer from Wolfia to keep a deal moving"
    reverseLayout={false}
  />
);

const UseCaseSecuritySection = () => (
  <UseCaseSectionLayout
    title="Let AI handle tedious security questionnaires"
    problem="Security reviews slow down deals. Teams waste precious hours digging for answers or waiting on busy security experts, causing delays and frustration"
    solution="Wolfia learns from your security documents and past answers. Our AI agent provides expert-level answers automatically – even directly in web portals like OneTrust and Zip. It functions like a dedicated expert working 24/7 on repetitive tasks"
    benefits={[
      "Answer questionnaires 5x faster, including web portals",
      "Free up security teams for strategic work, not repetition",
      "Ensure every answer is accurate and consistent",
      "Unblock deals faster by speeding through security reviews",
    ]}
    imageSrc="/images/home/usecase-security-questionnaire.png"
    imageAlt="Illustration showing Wolfia automating a security questionnaire, including web portals"
    reverseLayout={true}
  />
);
const UseCaseRFPSection = () => (
  <UseCaseSectionLayout
    title="Generate comprehensive RFP responses in minutes"
    problem="RFPs require fast, detailed answers. Chasing information across teams leads to rushed, inconsistent responses or missed opportunities altogether"
    solution="Stop the manual scramble. Wolfia uses your company knowledge to draft thorough RFP responses automatically. Handle spreadsheets, Word docs, and PDFs seamlessly. Focus on strategy, not copy-pasting"
    benefits={[
      "Slash RFP completion time dramatically",
      "Respond to more opportunities without overloading experts",
      "Improve proposal quality and consistency",
      "Submit winning RFPs faster and increase revenue",
    ]}
    imageSrc="/images/home/usecase-rfp-response.png"
    imageAlt="Illustration of Wolfia helping draft an RFP document quickly"
    reverseLayout={false}
  />
);


const BenefitItem = ({ icon, title, description, className = "" }) => (
  <div className={`relative p-6 rounded-xl border border-gray-100 bg-white overflow-hidden ${className}`}>
    <div className="relative z-10">
      <div className="mb-3">
        {React.cloneElement(icon, { className: "w-8 h-8 text-lupine-600" })}
      </div>
      <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
      <p className="text-sm text-gray-600">{description}</p>
    </div>
  </div>
);

const BenefitsSectionRevised = () => (
  <Section className="bg-white py-16 lg:py-24 px-4 sm:px-6 lg:px-8 border-t border-gray-200">
    <div className="max-w-7xl mx-auto">
      <div className='text-center mb-12 md:mb-16'>
        <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900 mb-3 tracking-tight">Operate more efficiently</h2>
        <p className="text-lg text-gray-600 max-w-5xl mx-auto">
          Wolfia automates repetitive work and centralizes knowledge so your teams can focus on higher-value activities
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 lg:gap-3">
        <BenefitItem
          icon={<BoltIcon />}
          title="Instant expertise, anytime"
          description={"Give sales and security the ability to answer complex buyer questions immediately. Our AI uses your verified knowledge base for accurate, sourced answers 24/7 via Slack or web app, helping maintain deal momentum."}
          className="col-span-1"
        />
        <BenefitItem
          icon={<SparklesIcon />}
          title="Automated questionnaires & RFPs"
          description={"Stop the manual work of security reviews and RFPs. Wolfia handles the heavy lifting, automating form filling directly in web portals (like OneTrust, Zip) and traditional files. Turn weeks of work into hours of review."}
          className="col-span-1"
        />
        <BenefitItem
          icon={<ScaleIcon />}
          title="Scale revenue efficiently"
          description={"Handle more volume, like 4x the security questionnaires, with your existing team. Focus investment on growth, knowing your teams can operate more efficiently."}
          className="col-span-1"
        />
        <BenefitItem
          icon={<LightBulbIcon />}
          title="Free experts for core work"
          description={"Engineers and security professionals are critical assets. Stop interrupting them with repetitive questions. Wolfia handles routine inquiries, letting experts focus on innovation and strategic initiatives."}
          className="col-span-1"
        />
        <BenefitItem
          icon={<UserPlusIcon />}
          title="Faster team onboarding"
          description={"New sales reps get up to speed quicker with instant access to product and security knowledge. Reduce ramp time and increase productivity from the start."}
          className="col-span-1"
        />
        <BenefitItem
          icon={<ShieldCheckIcon />}
          title="Maintain control & accuracy"
          description={"Wolfia uses only *your* company's approved knowledge sources. You decide what the AI learns from. Get consistent, accurate answers aligned with your policies and messaging, every time."}
          className="col-span-1"
        />
      </div>
    </div>
  </Section>
);


const HowItWorksStep = ({ number, title, description, isLast, isActive, onHover }) => (
  <div
    onMouseEnter={() => onHover(number)}
    className={`relative flex items-start pb-10 pl-16 transition-opacity duration-300 ease-in-out group cursor-pointer ${isActive ? 'opacity-100' : 'opacity-70 hover:opacity-100'}`}
  >
    {!isLast && (
      <div className={`absolute left-[calc(1.5rem-1px)] top-12 bottom-0 w-0.5 transition-colors duration-300 ${isActive ? 'bg-lupine-400' : 'bg-lupine-200'}`}></div>
    )}
    <div className={`absolute left-0 top-0 flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center font-bold text-xl z-10 transition-all duration-300 ${isActive ? 'bg-lupine-500 text-white scale-110' : 'bg-lupine-100 text-lupine-700'}`}>
      {number}
    </div>
    <div className="ml-4">
      <h3 className={`text-xl font-semibold mb-2 transition-colors duration-300 ${isActive ? 'text-gray-900' : 'text-gray-700'}`}>{title}</h3>
      <p className={`transition-colors duration-300 ${isActive ? 'text-gray-700' : 'text-gray-600'}`}>{description.join(' ')}</p>
    </div>
  </div>
);

const HowWolfiaWorksStepsSection = () => {
  const [activeStep, setActiveStep] = useState(1);

  return (
    <Section className="bg-white py-16 lg:py-24 px-4 sm:px-6 lg:px-8 border-t border-gray-200">
      <div className="max-w-3xl mx-auto">
        <div className='text-center mb-12 md:mb-16'>
          <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900 mb-3 tracking-tight">How Wolfia turns knowledge into action</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">Four steps to centralize expertise and automate workflows</p>
        </div>
        <div>
          {[
            { number: 1, title: "Connect knowledge sources securely", description: ["Link Wolfia in minutes to where information lives: Google Drive, Slack, Notion, Confluence, websites, and more.", "Upload documents like policies or past questionnaires. You control exactly what Wolfia learns from."] },
            { number: 2, title: "AI builds & maintains a knowledge base", description: ["Wolfia's AI reads, understands context, and structures information into a reliable knowledge base – no manual tagging needed.", "It continuously learns from new content, prioritizing recent and relevant information, ensuring answers stay fresh. We handle the upkeep."] },
            { number: 3, title: "Get instant, trustworthy answers", description: ["Ask questions in plain English via Slack or web app. Wolfia searches your verified knowledge base, not the public internet.", "Receive precise answers in seconds, with source links for verification (consistently over 95% accurate)."] },
            { number: 4, title: "Automate answers everywhere", description: ["Delegate repetitive tasks to the AI agent. Wolfia drafts answers for security questionnaires (files & web portals), RFPs, and common buyer questions.", "Turn hours of manual search into minutes of review."] }
          ].map((step, index, arr) => (
            <HowItWorksStep
              key={step.number}
              number={step.number}
              title={step.title}
              description={step.description}
              isLast={index === arr.length - 1}
              isActive={activeStep === step.number}
              onHover={setActiveStep}
            />
          ))}
        </div>
      </div>
    </Section>
  );
};


const IntegrationsSection = () => {
  const baseIntegrations = [ { name: 'Slack', icon: '/images/logos/integrations/slack.png' }, { name: 'Google Drive', icon: '/images/logos/integrations/google-drive.png' }, { name: 'Notion', icon: '/images/logos/integrations/notion.png' }, { name: 'Email', icon: '/images/logos/integrations/email.png' }, { name: 'Website', icon: '/images/logos/integrations/website.png' }, { name: 'Gong', icon: '/images/logos/integrations/gong.png' }, { name: 'Vanta', icon: '/images/logos/integrations/vanta.png' }, { name: 'Salesforce', icon: '/images/logos/integrations/salesforce.png' }, { name: 'Clari', icon: '/images/logos/integrations/clari.png' }, { name: 'Confluence', icon: '/images/logos/integrations/confluence.png' }, ];
  const integrations = baseIntegrations.slice(0, 10);
  while (integrations.length < 10) {
    integrations.push({ name: '', icon: '/images/logos/integrations/placeholder.png', isPlaceholder: true });
  }

  return (
    <Section className="bg-white py-16 lg:py-24 px-4 sm:px-6 lg:px-16 xl:px-24 border-t border-gray-200">
      <div className="max-w-screen-xl mx-auto">
        <div className='text-center mb-12 md:mb-16'>
          <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900 mb-3 tracking-tight">Connect your knowledge ecosystem</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Wolfia learns from where your knowledge already lives
          </p>
        </div>
        <div className="border border-gray-200 rounded-xl overflow-hidden">
          <div className="grid grid-cols-5">
            {integrations.map((int, index) => (
              <div
                key={int.name || `placeholder-${index}`}
                className={`
                  flex flex-col items-center justify-center p-12 sm:p-16 h-56 sm:h-60
                  border-gray-200
                  ${index % 5 !== 4 ? 'border-r' : ''}
                  ${index < 5 ? 'border-b' : ''}
                  ${int.isPlaceholder ? 'opacity-0 pointer-events-none' : ''}
                `}
              >
                {!int.isPlaceholder && (
                  <>
                    <div className="relative w-16 h-16 sm:w-20 sm:h-20 mb-4">
                      <Image
                        src={int.icon}
                        alt={int.name ? `${int.name} logo` : ''}
                        layout="fill"
                        objectFit="contain"
                        loading="lazy"
                      />
                    </div>
                    <span className="text-sm font-medium text-gray-700 text-center">{int.name}</span>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
};


const TestimonialBlock = ({ quote, name, title, description, image, companySlug, cardWidth = 'col-span-1' }) => (
  <div className={`
      flex flex-col justify-between rounded-2xl p-8 border bg-white relative overflow-hidden min-h-[380px]
      transition-all duration-300 ease-in-out border-gray-200 hover:border-gray-300 hover:shadow-xl hover:scale-[1.02]
       ${cardWidth}
  `}>
    <div className="relative z-10 flex flex-col h-full">
      {companySlug && (
        <div className="mb-6 h-10 relative w-auto max-w-[120px] self-start">
          <Image
            src={`/images/logos/companies/${companySlug}.png`}
            alt={`${description} logo`}
            width={120}
            height={40}
            className="object-contain object-left"
            unoptimized
            priority
          />
        </div>
      )}

      <blockquote className="relative text-gray-800 leading-relaxed mb-8 text-lg flex-grow">
        <span className="relative z-10">"{quote.endsWith('.') ? quote.slice(0, -1) : quote}"</span>
      </blockquote>

      <div className="flex items-center mt-auto pt-6 border-t border-gray-100">
        <div className="relative h-12 w-12 flex-shrink-0">
          <Image
            src={image}
            alt={`Portrait photo of ${name}`}
            width={48}
            height={48}
            className="rounded-full object-cover"
            priority
          />
        </div>
        <div className="ml-4 flex flex-col">
          <div className="font-semibold text-gray-900 text-base tracking-tight">{name}</div>
          <div className="text-sm text-gray-600 tracking-tight">{title}</div>
          {description && <div className="text-xs text-gray-500 mt-0.5 tracking-tight">{description}</div>}
        </div>
      </div>
    </div>
  </div>
);

const TestimonialsModule = () => {
  const testimonials = [
    { quote:"When our team member left, I thought I'd be buried in security questionnaires. Instead, Wolfia's AI agent helped me handle 4X our usual volume... It's completely transformed our security review process", name:"Garrett Close", title:"Head of GRC", description:"Amplitude", image:"/images/home/landingPage_testimonials_portrait_garrett.png", companySlug:"amplitude"},
    { quote:"Nobody enjoys filling out security questionnaires and RFPs. Wolfia has dramatically reduced the repetitive work... It feels like having superpowers—I can tackle lengthy proposals swiftly without relying on subject matter experts", name:"Parker Lewis", title:"Account Executive", description:"Finley", image:"/images/home/landingPage_testimonials_portrait_parker.png", companySlug:"finley" },
    { quote:"Wolfia helps us turn around security questionnaires in half the time while letting our team focus on higher leverage work", name:"Will Lawrence", title:"Co-founder & CEO", description:"Greenlite", image:"/images/home/landingPage_testimonials_portrait_will.jpeg", companySlug:"greenlite" },
  ];

  return (
    <section className="bg-white py-16 lg:py-24 px-4 sm:px-6 lg:px-8 border-t border-gray-200">
      <div className="max-w-screen-xl mx-auto">
        <div className='text-center mb-12 md:mb-16'>
          <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900 mb-3 tracking-tight">How modern GTM teams stay ahead</h2>
          <p className="text-lg text-gray-600 max-w-4xl mx-auto">
            Early adopters use Wolfia's AI agent to automate workflows and gain a significant advantage
          </p>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-10'>
          {testimonials.map((testimonial, index) => (
            <TestimonialBlock key={index} {...testimonial} cardWidth="col-span-1" />
          ))}
        </div>
      </div>
    </section>
  );
};


const CTABoxSection = () => ( <Section contentWidth='content-lg' className='bg-white text-black py-16 lg:py-24 px-4 sm:px-6 border-t border-gray-200'> <CTABox /> </Section> );

export default function LandingPage({ title, url }) {

  return (
    <MarketingPage>
      <Meta
        title="Wolfia | Scale Expertise & Accelerate Revenue | AI for Sales & Security"
        description="Wolfia's AI agent helps sales and security teams automate questionnaires (inc. web portals), RFPs, and answer expert questions instantly. See value from Day 1."
        keywords="AI Sales Assistant, Security Questionnaire Automation, RFP Automation, Web Portal Automation AI, Sales Enablement AI, AI Knowledge Base, Technical Sales Support, GRC Automation, AI Agent, Fast Implementation"
      />
      <HeroContainer title={title} url={url} />
      <TimelineSection />
      <Section id="use-cases" className="bg-white pt-16 lg:pt-24 px-4 sm:px-6 lg:px-8 border-t border-gray-200">
        <div className="max-w-7xl mx-auto text-center mb-4 md:mb-8">
          <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900 mb-3 tracking-tight">How Wolfia helps unblock deals and save expert time</h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">Stop bottlenecks and let Sales and Security teams move faster</p>
        </div>
        <UseCaseExpertAnswersSection />
        <UseCaseSecuritySection />
        <UseCaseRFPSection />
      </Section>
      <BenefitsSectionRevised />
      <HowWolfiaWorksStepsSection />
      <IntegrationsSection />
      <TestimonialsModule/>
      <CTABoxSection/>
    </MarketingPage>
  );
}
