import { Button } from './Button';
import Heading from './Heading';
import { DEMO_URL } from '@/components/blog/DemoLink';

export const CTABox = ({
  roundedBorder = true,
  borderColor = 'lupine-100',
  border = true,
}) => {
  const borderColorClass = `border-${borderColor}`;
  const roundedClass = roundedBorder ? 'rounded-3xl' : '';
  const borderClass = border ? 'border' : '';

  return (
    <div
      className={`${borderClass} ${borderColorClass} ${roundedClass} bg-clip-border px-8 md:px-16 py-16 md:py-28 flex flex-col items-center gap-6 text-center bg-no-repeat bg-top bg-[length:100%]`}
      style={{ backgroundImage: 'url(/images/logos/logo-stroke.svg)' }}
    >
      <Heading level="h2" align="center">
        Ready to accelerate your deals?
      </Heading>
      <p className="text-lg text-gray-600 max-w-2xl mx-auto -mt-2 mb-2">
        Stop letting repetitive questions and manual tasks slow you down. See how Wolfia can empower your team today.
      </p>
      <div className="flex flex-col sm:flex-row gap-4">
        <Button
          CTA="Schedule a demo"
          href={DEMO_URL}
          variant="primary"
          width="auto"
        />
      </div>
    </div>
  );
};
