import React from 'react';
import Image from 'next/image';

const LOGO_GRID_ITEM_HEIGHT = 'h-14 sm:h-16 md:h-20 lg:h-24';
const LOGO_GRID_GAP = 'gap-2 sm:gap-3 md:gap-4 lg:gap-6';

const logos = [
  {
    src: '/images/logos/companies/amplitude.png',
    width: 130,
    height: 55,
    alt: 'Amplitude - Product Analytics Platform',
    className: "w-[100%] h-[100%]"
  },
  {
    src: '/images/logos/companies/circle-ci.png',
    width: 130,
    height: 55,
    alt: 'CircleCI - Continuous Integration Platform',
    className: "w-[90%] h-[90%]"
  },
  {
    src: '/images/logos/companies/peregrine.png',
    width: 130,
    height: 55,
    alt: 'Peregrine - Data Intelligence Platform',
    className: "w-[80%] h-[80%]"
  },
  {
    src: '/images/logos/companies/lilt.png',
    width: 110,
    height: 45,
    alt: 'Lilt - AI Translation Service',
    className: "w-[50%] h-[50%]"
  },
  {
    src: '/images/logos/companies/sardine.png',
    width: 130,
    height: 55,
    alt: 'Sardine - Fraud Prevention Platform',
    className: "w-[80%] h-[80%]"
  },
];

const LogoGrid = ({
  itemHeight = LOGO_GRID_ITEM_HEIGHT,
  gridGap = LOGO_GRID_GAP,
  logosData = logos
}) => {
  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6">
      <div className={`grid grid-cols-2 ${gridGap} sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5`}>
        {logosData.map((logo, index) => (
          <div
            key={index}
            className={`flex ${itemHeight} items-center justify-center rounded-lg transition-all duration-300 group`}
          >
            <div className="relative w-full h-full flex items-center justify-center overflow-hidden rounded-lg">
              <Image
                src={logo.src}
                alt={logo.alt}
                width={logo.width}
                height={logo.height}
                className={`${logo.className} object-contain`}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const CompanyLogosSection = ({ logosData = logos }) => (
  <section className="pt-16 pb-8 sm:pt-24 sm:pb-8 bg-white">
    <LogoGrid logosData={logosData} />
  </section>
);

export default CompanyLogosSection;
