import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { CompanyContent, ControlItem } from '@/api/data-contracts';
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { CheckCircle2, Menu, XCircle } from 'lucide-react';
import { styled } from '@/stitches.config';

export const ControlsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '0 $large',
  '@media (max-width: 768px)': {
    padding: '0 $medium',
    flexDirection: 'column'
  },
  '@smallDevices': {
    padding: '0 $small'
  }
});

export const SidebarContainer = styled('div', {
  display: 'none',
  flexDirection: 'column',
  minWidth: '300px',
  paddingTop: '$large',
  paddingRight: '$large',
  position: 'sticky',
  top: 0,
  alignSelf: 'flex-start',
  height: 'fit-content',
  '@media (min-width: 1024px)': {
    display: 'flex'
  }
});

export const MobileCategoriesDropdown = styled('div', {
  display: 'none',
  marginBottom: '$medium',
  '@media (max-width: 1023px)': {
    display: 'block'
  }
});

export const MainContent = styled('div', {
  flex: 1,
  width: '100%'
});

export const SearchContainer = styled('div', {
  paddingTop: '$large',
  marginBottom: '$large',
  width: '100%',
  maxWidth: '400px',
  '@media (max-width: 768px)': {
    paddingTop: '$medium'
  }
});

export const CategorySection = styled('div', {
  marginBottom: '$large',
  paddingTop: '$medium',
  paddingBottom: '$medium'
});

export const MobileMenuButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  padding: '$small',
  marginBottom: '$medium',
  borderRadius: '$medium',
  border: '1px solid $silvermist-200',
  backgroundColor: 'white',
  cursor: 'pointer',
  '@media (min-width: 1024px)': {
    display: 'none'
  }
});

export const MobileMenu = styled('div', {
  display: 'none',
  position: 'absolute',
  backgroundColor: 'white',
  border: '1px solid $silvermist-200',
  borderRadius: '$medium',
  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  zIndex: 10,
  width: '100%',
  maxWidth: '300px',
  variants: {
    isOpen: {
      true: {
        display: 'block'
      }
    }
  }
});

export const MenuList = styled('ul', {
  padding: '$small 0',
  margin: 0,
  listStyle: 'none'
});

export const MenuItem = styled('li', {
  padding: '0',
  '& a': {
    display: 'block',
    padding: '$small $medium',
    textDecoration: 'none',
    color: '$gray-700',
    '&:hover': {
      backgroundColor: '$silvermist-50'
    }
  }
});

export const CategoryTitle = styled('h1', {
  fontSize: '1.5rem',
  fontWeight: 500,
  color: '#111827',
  marginBottom: '$medium'
});

export const NavLink = styled(Link, {
  display: 'block',
  padding: '$small 0',
  fontSize: '0.875rem',
  color: '#4B5563',
  textDecoration: 'none',
  '&:hover': {
    color: '#111827'
  }
});

export const MobileControlCard = styled('div', {
  border: '1px solid #e5e7eb',
  borderRadius: '8px',
  overflow: 'hidden',
  marginBottom: '16px',
  backgroundColor: 'white',
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
  display: 'none',
  '@media (max-width: 768px)': {
    display: 'block'
  }
});

export const CardHeader = styled('div', {
  padding: '16px',
  borderBottom: '1px solid #e5e7eb',
  backgroundColor: '#f9fafb'
});

export const CardContent = styled('div', {
  padding: '16px'
});

export const ControlName = styled('h3', {
  fontSize: '16px',
  fontWeight: '600',
  color: '#111827',
  marginBottom: '8px'
});

export const ControlSummary = styled('p', {
  fontSize: '14px',
  color: '#4b5563',
  marginBottom: '16px'
});

export const StatusContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
});

export const StatusText = styled('span', {
  fontSize: '14px',
  fontWeight: '500',
  variants: {
    status: {
      active: {
        color: '#047857'
      },
      inactive: {
        color: '#6b7280'
      }
    }
  }
});

export const DesktopTable = styled('div', {
  display: 'block',
  '@media (max-width: 768px)': {
    display: 'none'
  }
});

export function getTableStyles() {
  return {
    table: "border-collapse rounded-lg overflow-hidden border border-gray-200 w-full",
    headerRow: "bg-gray-50",
    headerCell: "py-4 px-4 text-sm font-semibold text-gray-600 border-b border-gray-200",
    row: "hover:bg-gray-50",
    cell: "py-4 px-4 border-b border-gray-200",
    firstCell: "pl-6 border-l border-gray-200",
    lastCell: "pr-6 border-r border-gray-200",
    icon: "h-5 w-5 inline-block"
  };
}

export default function TrustCenterControls({ companyContent }: { companyContent: CompanyContent }) {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 300);
      }
    }
  }, [router.asPath]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('#mobile-menu-button') && !target.closest('#mobile-menu')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const filteredControls = companyContent.controls.map((control) => ({
    ...control,
    items: control.items.filter((item) =>
      searchTerm === '' ||
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.summary.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })).filter((controlData) => controlData.items.length > 0);

  const toUrlFriendlyId = (categoryTitle: string) =>
    categoryTitle.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');

  return (
    <ControlsContainer>
      <SidebarContainer>
        <p className="text-lg font-medium text-gray-900 mb-2">Controls</p>
        <nav>
          <MenuList>
            {filteredControls.map(({ category }) => {
              const categoryId = toUrlFriendlyId(category);
              return (
                <MenuItem key={categoryId}>
                  <NavLink href={`/?tab=controls#${categoryId}`}>
                    {category}
                  </NavLink>
                </MenuItem>
              );
            })}
          </MenuList>
        </nav>
      </SidebarContainer>

      <MainContent>
        <SearchContainer>
          <Input
            type="search"
            placeholder="Search for controls"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full"
          />
        </SearchContainer>

        <MobileCategoriesDropdown>
          <MobileMenuButton
            id="mobile-menu-button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Menu className="h-5 w-5 mr-2" />
            <span className="text-sm font-medium">Categories</span>
          </MobileMenuButton>

          <MobileMenu id="mobile-menu" isOpen={isMenuOpen}>
            <MenuList>
              {filteredControls.map(({ category }) => {
                const categoryId = toUrlFriendlyId(category);
                return (
                  <MenuItem key={`mobile-${categoryId}`}>
                    <NavLink
                      href={`/?tab=controls#${categoryId}`}
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {category}
                    </NavLink>
                  </MenuItem>
                );
              })}
            </MenuList>
          </MobileMenu>
        </MobileCategoriesDropdown>

        {filteredControls.map(({ category, items }) => renderControlsCategory(category, items))}
      </MainContent>
    </ControlsContainer>
  );
}

function renderControlsCategory(title: string, items: ControlItem[]) {
  const titleId = title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
  const styles = getTableStyles();

  return (
    <CategorySection id={titleId} key={titleId}>
      <CategoryTitle>{title}</CategoryTitle>

      {/* Desktop Table View */}
      <DesktopTable>
        <Table className={styles.table}>
          <TableHeader>
            <TableRow className={styles.headerRow}>
              <TableHead className={`${styles.headerCell} ${styles.firstCell}`}>Control</TableHead>
              <TableHead className={`${styles.headerCell} ${styles.lastCell} w-[100px] text-center`}>Status</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {items.map((control, index) => (
              <TableRow key={`${control.name}-${index}`} className={styles.row}>
                <TableCell className={`${styles.cell} ${styles.firstCell}`}>
                  <div className="space-y-2">
                    <p className="font-medium text-gray-900">{control.name}</p>
                    <p className="text-sm text-gray-600 whitespace-pre-wrap">{control.summary}</p>
                  </div>
                </TableCell>
                <TableCell className={`${styles.cell} ${styles.lastCell} text-center`}>
                  {control.status ?
                    <CheckCircle2 className={`${styles.icon} text-green-500`} /> :
                    <XCircle className={`${styles.icon} text-gray-400`} />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DesktopTable>

      {/* Mobile Card View */}
      {items.map((control, index) => (
        <MobileControlCard key={`mobile-${control.name}-${index}`}>
          <CardHeader>
            <ControlName>{control.name}</ControlName>
          </CardHeader>
          <CardContent>
            <ControlSummary>{control.summary}</ControlSummary>
            <StatusContainer>
              {control.status ? (
                <>
                  <CheckCircle2 className="h-5 w-5 text-green-500" />
                  <StatusText status="active">Implemented</StatusText>
                </>
              ) : (
                <>
                  <XCircle className="h-5 w-5 text-gray-400" />
                  <StatusText status="inactive">Not implemented</StatusText>
                </>
              )}
            </StatusContainer>
          </CardContent>
        </MobileControlCard>
      ))}
    </CategorySection>
  );
}
