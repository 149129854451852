import { CompanyContent, ResourceModel } from '@/api/data-contracts';
import { Text } from '@/components/typography/Text';
import { Flex, Table } from '@radix-ui/themes';
import React, { useState } from 'react';
import { styled } from '@/stitches.config';
import { Button } from '@/components/inputs/Button';
import { ArrowDownTrayIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const ResourcesContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '$large 0',
  '@media (max-width: 768px)': {
    padding: '$medium 0',
  }
});

const ResourceHeader = styled(Flex, {
  marginBottom: '$large',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    gap: '$medium',
    alignItems: 'flex-start',
  }
});

const ResourcesTable = styled(Table.Root, {
  width: '100%',
  '@media (max-width: 768px)': {
    display: 'none'
  }
});

const MobileResourceCards = styled('div', {
  display: 'none',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  '@media (max-width: 768px)': {
    display: 'flex'
  }
});

const ResourceCard = styled('div', {
  border: '1px solid #e5e7eb',
  borderRadius: '8px',
  overflow: 'hidden',
  marginBottom: '16px',
  backgroundColor: 'white',
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
});

const ResourceCardContent = styled('div', {
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
});

const ResourceCardSection = styled('div', {
  marginBottom: '12px',
  '&:last-child': {
    marginBottom: 0
  }
});

const ResourceTitle = styled(Text, {
  fontWeight: '600',
  color: '#111827',
  marginBottom: '8px'
});

const ResourceDescription = styled(Text, {
  color: '#4b5563',
  marginBottom: '16px'
});

const NoResources = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '48px 0',
  width: '100%',
  minHeight: '200px',
  backgroundColor: '#f9fafb',
  borderRadius: '8px',
  border: '1px solid #e5e7eb'
});

const DownloadButton = styled(Button, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  fontSize: '14px',
  fontWeight: '500',
  borderRadius: '6px',
  height: '36px',
  padding: '0 16px',
  transition: 'all 0.2s ease-in-out',
  border: '1px solid',
  borderColor: '$silvermist-200',
  backgroundColor: 'white',
  color: '$gray-700',
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
  whiteSpace: 'nowrap',
  minWidth: '100px',
  cursor: 'pointer',

  '&:hover': {
    transform: 'translateY(-1px)',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    borderColor: '$silvermist-300',
    backgroundColor: '$silvermist-50'
  },

  '&:active': {
    transform: 'translateY(0px)',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    backgroundColor: '$silvermist-100'
  },

  '&:focus': {
    outline: 'none',
    boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)'
  },

  '&:disabled': {
    opacity: 0.6,
    cursor: 'not-allowed',
    transform: 'none',
    boxShadow: 'none'
  },

  variants: {
    variant: {
      primary: {
        backgroundColor: '$pineglade-500',
        borderColor: '$pineglade-600',
        color: 'white',
        '&:hover': {
          backgroundColor: '$pineglade-600',
          borderColor: '$pineglade-700',
          transform: 'translateY(-1px)',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
        },
        '&:active': {
          backgroundColor: '$pineglade-700',
          transform: 'translateY(0px)',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
        }
      },
      secondary: {
        backgroundColor: 'white',
        borderColor: '$silvermist-200',
        color: '$gray-700',
        '&:hover': {
          backgroundColor: '$silvermist-50',
          borderColor: '$silvermist-300',
          transform: 'translateY(-1px)',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
        },
        '&:active': {
          backgroundColor: '$silvermist-100',
          transform: 'translateY(0px)',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
        }
      },
      danger: {
        backgroundColor: '#f56565',
        borderColor: '#e53e3e',
        color: 'white',
        '&:hover': {
          backgroundColor: '#e53e3e',
          borderColor: '#c53030',
          transform: 'translateY(-1px)',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
        },
        '&:active': {
          backgroundColor: '#c53030',
          transform: 'translateY(0px)',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
        }
      }
    },
    size: {
      small: {
        height: '32px',
        fontSize: '12px',
        padding: '0 12px',
        minWidth: '80px'
      },
      medium: {
        height: '36px',
        fontSize: '14px',
        padding: '0 16px',
        minWidth: '100px'
      },
      large: {
        height: '42px',
        fontSize: '16px',
        padding: '0 20px',
        minWidth: '120px'
      }
    },
    fullWidth: {
      true: {
        width: '100%'
      }
    },
    loading: {
      true: {
        position: 'relative',
        color: 'transparent',
        pointerEvents: 'none',
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '20px',
          height: '20px',
          top: 'calc(50% - 10px)',
          left: 'calc(50% - 10px)',
          border: '2px solid rgba(255, 255, 255, 0.2)',
          borderTopColor: 'currentColor',
          borderRadius: '50%',
          animation: 'spin 0.7s linear infinite'
        }
      }
    }
  },
  defaultVariants: {
    variant: 'secondary',
    size: 'medium',
    fullWidth: false,
    loading: false
  }
});

export default function TrustCenterResource({ companyContent, setOpenRequestAccessDialog, isDownloadable }: {
  companyContent: CompanyContent,
  setOpenRequestAccessDialog: (bool) => void,
  isDownloadable: boolean
}) {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleBulkDownload = async () => {
    setIsDownloading(true);
    const zip = new JSZip();
    const downloadsFolder = zip.folder("downloads");
    const resources = companyContent.resources.filter(resource => (isDownloadable || resource.is_public) && resource.url);
    const downloadPromises = resources.map(async (resource) => {
      try {
        const response = await fetch(resource.url);
        if (response.status === 200) {
          const blob = await response.blob();
          downloadsFolder.file(resource.title.replace(/\s+/g, '_') + '.pdf', blob);
        } else {
          throw new Error('Resource download failed');
        }
      } catch (error) {
        console.error(`Failed to download ${resource.title}:`, error);
      }
    });

    await Promise.all(downloadPromises);
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "resources.zip");
      setIsDownloading(false);
    }).catch(() => {
      setIsDownloading(false);
    });
  };

  return (
    <ResourcesContainer>
      <ResourceHeader justify="between" align="center">
        <Text as="h1" size="large" weight="medium" color="primary">
          Resources
        </Text>
        {isDownloadable && companyContent.resources.length > 0 && (
          <DownloadButton
            as="button"
            variant="primary"
            onClick={handleBulkDownload}
            loading={isDownloading}
          >
            {isDownloading ? 'Downloading...' : 'Download All'}
            {!isDownloading && <ArrowDownTrayIcon width={16} height={16} />}
          </DownloadButton>
        )}
      </ResourceHeader>

      {companyContent.resources.length > 0 ? (
        <>
          <ResourcesTable size="1" variant="surface">
            <Table.Body>
              {companyContent.resources.map((resource, index) => (
                <Table.Row key={`table-${resource.title}-${index}`}>
                  <Table.Cell style={{ width: '30%', padding: '16px' }}>
                    <Text as="p" size="medium" weight="semibold">
                      {resource.title}
                    </Text>
                  </Table.Cell>
                  <Table.Cell style={{ width: '50%', padding: '16px' }}>
                    <Text as="p" size="medium" color="secondary">
                      {resource.description}
                    </Text>
                  </Table.Cell>
                  <Table.Cell style={{ width: '20%', padding: '16px', textAlign: 'center' }}>
                    {(isDownloadable || resource.is_public) ? (
                      <DownloadButton
                        as="button"
                        variant="secondary"
                        onClick={() => window.open(resource.url)}
                      >
                        Download
                        <ArrowDownTrayIcon width={16} height={16} />
                      </DownloadButton>
                    ) : (
                      <DownloadButton
                        as="button"
                        variant="secondary"
                        onClick={() => setOpenRequestAccessDialog(true)}
                      >
                        Request Access
                        <LockClosedIcon width={16} height={16} />
                      </DownloadButton>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </ResourcesTable>

          <MobileResourceCards>
            {companyContent.resources.map((resource, index) => (
              <ResourceCard key={`mobile-${resource.title}-${index}`}>
                <ResourceCardContent>
                  <ResourceCardSection>
                    <ResourceTitle as="p" size="medium">
                      {resource.title}
                    </ResourceTitle>
                    <ResourceDescription as="p" size="medium" color="secondary">
                      {resource.description}
                    </ResourceDescription>
                  </ResourceCardSection>
                  <ResourceCardSection>
                    {(isDownloadable || resource.is_public) ? (
                      <DownloadButton
                        as="button"
                        variant="secondary"
                        onClick={() => window.open(resource.url)}
                      >
                        Download
                        <ArrowDownTrayIcon width={16} height={16} />
                      </DownloadButton>
                    ) : (
                      <DownloadButton
                        as="button"
                        variant="secondary"
                        onClick={() => setOpenRequestAccessDialog(true)}
                      >
                        Request Access
                        <LockClosedIcon width={16} height={16} />
                      </DownloadButton>
                    )}
                  </ResourceCardSection>
                </ResourceCardContent>
              </ResourceCard>
            ))}
          </MobileResourceCards>
        </>
      ) : (
        <NoResources>
          <Text as="p" size="medium" color="secondary">
            No resources available
          </Text>
        </NoResources>
      )}
    </ResourcesContainer>
  );
}
