import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { CompanyContent } from '@/api/data-contracts';
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { CheckCircle2, Menu, XCircle } from 'lucide-react';
import { styled } from '@/stitches.config';
import {
  CardContent,
  CardHeader,
  CategorySection,
  CategoryTitle,
  ControlName,
  ControlsContainer,
  ControlSummary,
  DesktopTable,
  getTableStyles,
  MainContent,
  MenuItem,
  MenuList,
  MobileCategoriesDropdown,
  MobileControlCard,
  MobileMenu,
  MobileMenuButton,
  NavLink,
  SearchContainer,
  SidebarContainer,
  StatusContainer,
  StatusText
} from "@/components/trust/controls";

const CategorySummaryText = styled('p', {
  color: '#4B5563',
  marginBottom: '$medium',
  fontSize: '0.875rem',
  lineHeight: '1.5'
});

export default function TrustCenterISOControls({ companyContent }: { companyContent: CompanyContent }) {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 300);
      }
    }
  }, [router.asPath]);

  useEffect(() => {
    // Close menu when user clicks outside
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('#iso-menu-button') && !target.closest('#iso-mobile-menu')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const filteredControls = companyContent.iso_controls.map((control) => ({
    ...control,
    items: control.items.filter((item) =>
      searchTerm === '' ||
      item.control.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.criteria.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.topic.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.resources.join().toLowerCase().includes(searchTerm.toLowerCase())
    )
  })).filter((controlData) => controlData.items.length > 0);

  const toUrlFriendlyId = (categoryTitle: string) =>
    categoryTitle.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');

  return (
    <ControlsContainer>
      <SidebarContainer>
        <p className="text-lg font-medium text-gray-900 mb-2">ISO 42001 Controls</p>
        <nav>
          <MenuList>
            {filteredControls.map(({ category }) => {
              const categoryId = toUrlFriendlyId(category);
              return (
                <MenuItem key={categoryId}>
                  <NavLink href={`/?tab=iso_controls#${categoryId}`}>
                    {category}
                  </NavLink>
                </MenuItem>
              );
            })}
          </MenuList>
        </nav>
      </SidebarContainer>

      <MainContent>
        <SearchContainer>
          <Input
            type="search"
            placeholder="Search for controls"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full"
          />
        </SearchContainer>

        <MobileCategoriesDropdown>
          <MobileMenuButton
            id="iso-menu-button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Menu className="h-5 w-5 mr-2" />
            <span className="text-sm font-medium">ISO Categories</span>
          </MobileMenuButton>

          <MobileMenu id="iso-mobile-menu" isOpen={isMenuOpen}>
            <MenuList>
              {filteredControls.map(({ category }) => {
                const categoryId = toUrlFriendlyId(category);
                return (
                  <MenuItem key={`mobile-${categoryId}`}>
                    <NavLink
                      href={`/?tab=iso_controls#${categoryId}`}
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {category}
                    </NavLink>
                  </MenuItem>
                );
              })}
            </MenuList>
          </MobileMenu>
        </MobileCategoriesDropdown>

        {filteredControls.map(({ category, summary, items }) => {
          const titleId = category.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
          const styles = getTableStyles();

          return (
            <CategorySection id={titleId} key={titleId}>
              <CategoryTitle>{category}</CategoryTitle>
              <CategorySummaryText>{summary}</CategorySummaryText>

              {/* Desktop Table View */}
              <DesktopTable>
                <Table className={styles.table}>
                  <TableHeader>
                    <TableRow className={styles.headerRow}>
                      <TableHead className={`${styles.headerCell} ${styles.firstCell}`}>Criteria</TableHead>
                      <TableHead className={styles.headerCell}>Topic</TableHead>
                      <TableHead className={styles.headerCell}>Control</TableHead>
                      <TableHead className={`${styles.headerCell} ${styles.lastCell} w-[80px] text-center`}>Status</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {items.map((control, index) => (
                      <TableRow key={`table-${control.criteria}-${index}`} className={styles.row}>
                        <TableCell className={`${styles.cell} ${styles.firstCell} font-medium`}>{control.criteria}</TableCell>
                        <TableCell className={styles.cell}>{control.topic}</TableCell>
                        <TableCell className={styles.cell}>{control.control}</TableCell>
                        <TableCell className={`${styles.cell} ${styles.lastCell} text-center`}>
                          {control.status ?
                            <CheckCircle2 className={`${styles.icon} text-green-500`} /> :
                            <XCircle className={`${styles.icon} text-gray-400`} />
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DesktopTable>

              {/* Mobile Card View */}
              {items.map((control, index) => (
                <MobileControlCard key={`mobile-${control.criteria}-${index}`}>
                  <CardHeader>
                    <ControlName>{control.criteria}</ControlName>
                  </CardHeader>
                  <CardContent>
                    <div>
                      <strong className="text-sm text-gray-500 block mb-1">Topic:</strong>
                      <ControlSummary>{control.topic}</ControlSummary>
                    </div>
                    <div className="mb-4">
                      <strong className="text-sm text-gray-500 block mb-1">Control:</strong>
                      <ControlSummary>{control.control}</ControlSummary>
                    </div>
                    <StatusContainer>
                      {control.status ? (
                        <>
                          <CheckCircle2 className="h-5 w-5 text-green-500" />
                          <StatusText status="active">Implemented</StatusText>
                        </>
                      ) : (
                        <>
                          <XCircle className="h-5 w-5 text-gray-400" />
                          <StatusText status="inactive">Not implemented</StatusText>
                        </>
                      )}
                    </StatusContainer>
                  </CardContent>
                </MobileControlCard>
              ))}
            </CategorySection>
          );
        })}
      </MainContent>
    </ControlsContainer>
  );
}
